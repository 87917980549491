import { render, staticRenderFns } from "./education-timeline-item.vue?vue&type=template&id=3fd39d23&"
import script from "./education-timeline-item.vue?vue&type=script&lang=js&"
export * from "./education-timeline-item.vue?vue&type=script&lang=js&"
import style0 from "./education-timeline-item.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VRow,VTimelineItem})
