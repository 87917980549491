var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"app":""}},[_c('span',[_c('v-app-bar',{staticClass:"elevation-0",class:_vm.currentActiveArea,attrs:{"app":"","dark":"","inverted-scroll":"","scroll-target":"#main-container","height":"74px"}},[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.currentActiveTab),callback:function ($$v) {_vm.currentActiveTab=$$v},expression:"currentActiveTab"}},[_c('v-tab',{on:{"click":function($event){return _vm.scrollTo('professional')}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"dark":"","size":"36"}},[_vm._v("mdi-account-tie")]),_vm._v(" "+_vm._s(_vm.$t("professional_experience_name")))],1),_c('v-tab',{on:{"click":function($event){return _vm.scrollTo('education')}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"dark":"","size":"36"}},[_vm._v("mdi-school")]),_vm._v(" "+_vm._s(_vm.$t("education_name")))],1),_c('v-tab',{on:{"click":function($event){return _vm.scrollTo('skills')}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"dark":"","size":"36"}},[_vm._v("mdi-head-lightbulb")]),_vm._v(" "+_vm._s(_vm.$t("skills_name")))],1),_c('v-tab',{on:{"click":function($event){return _vm.scrollTo('projects')}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"dark":"","size":"36"}},[_vm._v("mdi-account-tie")]),_vm._v(" "+_vm._s(_vm.$t("projects_name")))],1)],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.lgAndUp)?_c('LanguageSwitcher'):_vm._e()],1),_c('v-main',{ref:"maincontainerref",attrs:{"id":"main-container"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"hidden":_vm.$vuetify.breakpoint.lgAndUp,"cols":"12"}},[_c('AboutMe')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ProfessionalExperience',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
              callback: _vm.visibilityChangedProfessional,
              throttle: 300,
            }),expression:"{\n              callback: visibilityChangedProfessional,\n              throttle: 300,\n            }"}],ref:"professional"}),_c('Education',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
              callback: _vm.visibilityChangedEducation,
              throttle: 300,
            }),expression:"{\n              callback: visibilityChangedEducation,\n              throttle: 300,\n            }"}],ref:"education"}),_c('Skills',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
              callback: _vm.visibilityChangedSkills,
              throttle: 300,
            }),expression:"{\n              callback: visibilityChangedSkills,\n              throttle: 300,\n            }"}],ref:"skills"}),_c('Projects',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
              callback: _vm.visibilityChangedProjects,
              throttle: 300,
            }),expression:"{\n              callback: visibilityChangedProjects,\n              throttle: 300,\n            }"}],ref:"projects"})],1),_c('v-col',{attrs:{"id":"scrollingcard","hidden":_vm.$vuetify.breakpoint.mdAndDown,"cols":"3"}},[_c('AboutMe',{staticClass:"ma-4"})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }